import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

export default function CategoryItem({ item }) {
  return (
    <div className="d-flex position-relative category-slide-image my-4 px-2 px-md-0 category">
      <Link href={`/category/${item?.translations?.mn_MN?.slug}`}>
        <img
          className="img-thumbnail border-0 pt-0 py-0 px-0 shadow-sm"
          src={
            item?.images[0]?.path
              ? process.env.BASE_IMAGE_URL + item?.images[0]?.path
              : ''
          }
        />
      </Link>
    </div>
  )
}
