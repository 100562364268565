import axios from 'axios'
import { useApiHandler } from 'actions'

const createToken = (data) =>
  axios.post(`${process.env.BASE_URL}shop/custom/authentication-token`, data)

const createRegister = (data) =>
  axios.post(`${process.env.BASE_URL}shop/register`, data)

const createLogin = (data) =>
  axios.post(`${process.env.BASE_URL}shop/custom/authentication-token`, data)

const createForgot = (data) =>
  axios.post(`${process.env.BASE_URL}shop/password-request`, data)

const getConfirmationCode = (code, phoneNumber) =>
  axios.get(`${process.env.BASE_URL}shop/verify/${phoneNumber}/token/${code}`)

const createPassword = (data, resetToken) =>
  axios.put(`${process.env.BASE_URL}shop/password-reset/${resetToken}`, data)

const getCurrentUser = (token) =>
  axios.get(`${process.env.BASE_URL}shop/me`, {
    headers: {
      'Content-Type': 'application/merge-patch+json',
      Authorization: token ? `Bearer ${token}` : null,
      'Cache-Control': 'no-store',
    },
  })

const getFacebook = () =>
  axios.get(`${process.env.BASE_URL}shop/connect/facebook`)

const socialPayLogin = (data) =>
  axios.post(`${process.env.BASE_URL}shop/connect/social-pay-mini`, data)

const lendPayLogin = (data, token) => axios.post(`${process.env.BASE_URL}/shop/sales-token`, data, {
  headers: {'lend-sales-token': token}
})
const mostmoneyLogin = (data) =>
  axios.post(`${process.env.BASE_URL}shop/authenticate/most-money`, data)

const createRefreshToken = (data) =>
  axios.post(`${process.env.BASE_URL}shop/token/refresh`, data)

export const useCreateToken = () => useApiHandler(createToken)
export const useCreateTLogin = () => useApiHandler(createLogin)
export const useCreateForgot = () => useApiHandler(createForgot)
export const useConfirmationCode = () => useApiHandler(getConfirmationCode)
export const usePassword = () => useApiHandler(createPassword)
export const useRegister = () => useApiHandler(createRegister)
export const useCurrentUser = () => useApiHandler(getCurrentUser)
export const useFacebook = () => useApiHandler(getFacebook)
export const useSocialPayLogin = () => useApiHandler(socialPayLogin)
export const useMostmoneyLogin = () => useApiHandler(mostmoneyLogin)
export const useLendPayLogin = () => useApiHandler(lendPayLogin)
export const useRefreshToken = () => useApiHandler(createRefreshToken)
