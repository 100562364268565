import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import isEmpty from 'utils/isEmpty'

export default function Banner({ banner }) {
  const placeholderImg = '/product-img-placeholder.svg'
  const mainSlide = banner?.translations?.mn_MN?.imageName
  const mobileMainSlide = banner?.translations?.mn_MN?.mobileImageName
  return (
    <>
      {banner ? (
        <div className="new-product-list">
          <div className="container">
            <div className="px-md-4 mx-md-4">
              <div
                className="d-none d-sm-none d-md-block d-lg-block"
                style={{
                  cursor: isEmpty(banner?.translations?.mn_MN?.url)
                    ? 'default'
                    : 'pointer',
                }}
              >
                <Link
                  href={`${
                    isEmpty(banner?.translations?.mn_MN?.url)
                      ? '/'
                      : banner?.translations?.mn_MN?.url
                  }`}
                >
                  <img
                    className="img-fluid"
                    src={
                      mainSlide
                        ? process.env.BASE_IMAGE_BANNER_URL + mainSlide
                        : placeholderImg
                    }
                  />
                </Link>
              </div>

              <div
                className="d-block d-sm-block d-md-none d-lg-none"
                style={{
                  cursor: isEmpty(banner?.translations?.mn_MN?.url)
                    ? 'default'
                    : 'pointer',
                }}
              >
                <Link
                  href={`${
                    isEmpty(banner?.translations?.mn_MN?.url)
                      ? '/'
                      : banner?.translations?.mn_MN?.url
                  }`}
                >
                  <img
                    className="img-fluid"
                    src={
                      mobileMainSlide
                        ? process.env.BASE_IMAGE_BANNER_URL + mobileMainSlide
                        : placeholderImg
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
