import Link from 'next/link'
import React from 'react'
import Discount from './discount'

const Banner5 = ({ banner, encodeImageURI, isMobile }) => {
  const disc = banner?.mainText?.split('&')?.[1] || undefined
  const mainText = banner?.mainText?.split('&')?.[0] || ''
  return (
    <Link href={banner?.url || '#'}>
      <div
        className="overflow-hidden rounded-5 position-relative cursor-pointer"
        style={{
          width: '100%',
          height: '35%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${
            banner ? encodeImageURI(banner?.imageName) : ''
          })`,
        }}
      >
        {/* dark overlay for the banner start */}
        <div
          className="absolute top-0 left-0 right-0 bottom-0 h-100 w-100"
          style={{
            backgroundColor: 'rgba(0,0,0,0.1)',
          }}
        />
        {/* dark overlay for the banner end */}
        {/* disc start */}
        <Discount disc={disc} isMobile={isMobile} />
        {/* disc end */}

        <div className="position-absolute top-0 start-0 h-100 w-100">
          <span
            className="position-absolute text-white font-weight-bold flex-wrap lh-1"
            style={{
              fontSize: isMobile ? '20px' : '35px',
              top: isMobile ? '15px' : '25px',
              left: isMobile ? '20px' : '25px',
            }}
            dangerouslySetInnerHTML={{ __html: mainText || '' }}
          />
          <button
            className="banner-btn position-absolute"
            style={{
              right: isMobile ? '15px' : '20px',
              bottom: isMobile ? '15px' : '20px',
            }}
          >
            Бүгдийг харах
          </button>
        </div>
      </div>
    </Link>
  )
}

export default Banner5
