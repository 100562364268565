import React, { useEffect, useMemo, useState } from 'react'
import Link from 'next/link'
import ViewTitle from 'components/core/components/TextSection'

import { chunk } from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import useMediaQuery from 'components/hooks/useMediaQuery'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import ProductBoxLoading from 'components/loading/productBoxLoading'

SwiperCore.use([Pagination, Autoplay])
export default function LendPromotion({
  category,
  banners,
  backgroundBanners,
}) {
  const [loading, setLoading] = useState(true)

  const isMobile = useMediaQuery('(max-width: 638px)')

  const getBanners = useMemo(() => {
    if (isMobile) {
      return chunk(banners, banners.length / 2)
    }
    return banners
  }, [banners, isMobile])

  useEffect(() => {
    if (getBanners) {
      setLoading(false)
    }
  }, [getBanners])

  function findBannerById(banners = []) {
    const id = '/new-api/shop/banners/BlackFridayBackground12'
    for (var i = 0; i < banners.length; i++) {
      if (banners[i]['@id'] === id && banners[i]) {
        return banners[i]?.translations?.mn_MN
      }
    }
    return null
  }

  return (
    <>
      <div
        className="new-product-list lunarNewYear-container"
        style={{
          backgroundImage: isMobile
            ? `url(${process.env.BASE_IMAGE_BANNER_URL}${
                findBannerById(backgroundBanners)?.mobileImageName
              })`
            : `url(${process.env.BASE_IMAGE_BANNER_URL}${
                findBannerById(backgroundBanners)?.imageName
              })`,
        }}
      >
        <div
          className="d-flex flex-column align-items-center gap-2 mt-2"
          style={{ color: '#373942' }}
        >
          <p style={{ marginTop: '2rem' }} className="f-text-title">
            {category?.translations?.mn_MN.name}
          </p>
          <div className="d-line ms-1" />
          <Link
            href={
              '/category/technology/ger-akhuin-tsakhilgaan-baraa/khiamdral' ||
              '/'
            }
          >
            <div className="f-text">
              Дэлгэрэнгүй харах
              <img src="/static/images/ui/arrow-forward.svg" />
            </div>
          </Link>
        </div>
        <div className="container px-4 box-sizing position-relative overflow-hidden">
          <div className="justify-content-center px-lg-3 mx-lg-2 pb-4">
            <div className="px-2 position-relative mt-4">
              <>
                {loading ? (
                  <>
                    <div className="row d-none d-xl-flex">
                      {Array.from(Array(5)).map((val, ind) => {
                        return (
                          <div className="col" key={ind}>
                            <ProductBoxLoading width={'100%'} />
                          </div>
                        )
                      })}
                    </div>
                    <div className="row d-none d-md-flex d-xl-none">
                      {Array.from(Array(4)).map((val, ind) => {
                        return (
                          <div className="col" key={ind}>
                            <ProductBoxLoading width={'100%'} />
                          </div>
                        )
                      })}
                    </div>
                    <div className="row d-md-none">
                      {Array.from(Array(2)).map((val, ind) => {
                        return (
                          <div className="col" key={ind}>
                            <ProductBoxLoading width={'100%'} />
                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <Swiper
                      slidesPerView={2}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      setWrapperSize={false}
                      // grabCursor={true}
                      pagination={{
                        bulletActiveClas: 'p-bullet-active',
                        bulletClass: 'p-bullet',
                        el: '.p-slider-pagination',
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: '.p-slider-next-button',
                        prevEl: '.p-slider-prev-button',
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        700: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                      }}
                      loop={true}
                    >
                      <div className="swiper-wrapper px-4 mx-4 box-sizing">
                        {(isMobile ? getBanners[0] : getBanners || []).map(
                          (banner, index) => {
                            return (
                              <div key={index} className="position-relative">
                                <SwiperSlide className="box-sizing" key={index}>
                                  <Image banner={banner} />
                                </SwiperSlide>
                              </div>
                            )
                          }
                        )}
                      </div>
                      {isMobile ? (
                        <div
                          className="swiper-pagination p-slider-pagination"
                          style={{ bottom: 0 }}
                        />
                      ) : null}
                    </Swiper>
                    {isMobile ? (
                      <Swiper
                        slidesPerView={2}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        setWrapperSize={false}
                        // grabCursor={true}
                        pagination={{
                          bulletActiveClas: 'p-bullet-active',
                          bulletClass: 'p-bullet',
                          el: '.p-slider-pagination',
                          clickable: true,
                        }}
                        navigation={{
                          nextEl: '.p-slider-next-button',
                          prevEl: '.p-slider-prev-button',
                        }}
                        breakpoints={{
                          640: {
                            slidesPerView: 2,
                            spaceBetween: 5,
                          },
                          700: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                          768: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                          },
                          1024: {
                            slidesPerView: 5,
                            spaceBetween: 10,
                          },
                        }}
                        loop={true}
                      >
                        <div className="swiper-wrapper px-4 mx-4 box-sizing">
                          {(getBanners[1] || []).map((banner, index) => {
                            return (
                              <div key={index} className="position-relative">
                                <SwiperSlide className="box-sizing" key={index}>
                                  <Image banner={banner} />
                                </SwiperSlide>
                              </div>
                            )
                          })}
                        </div>
                        <div
                          className="swiper-pagination p-slider-pagination"
                          style={{ bottom: 0 }}
                        />
                      </Swiper>
                    ) : null}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Image = ({ banner }) => {
  return (
    <div className="d-flex position-relative category-slide-image my-4 px-2 category">
      <Link
        href={`/category/${banner?.translations?.mn_MN?.secondaryText}` || '/'}
      >
        <img
          className="img-thumbnail border-0 pt-0 py-0 px-0  shadow-sm"
          src={
            banner?.translations?.mn_MN.imageName
              ? process.env.BASE_IMAGE_BANNER_URL +
                banner.translations?.mn_MN.imageName
              : ''
          }
          alt="banner"
        />
      </Link>
    </div>
  )
}
