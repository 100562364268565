import { useRouter } from 'next/router'
import React from 'react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useMediaQuery from 'components/hooks/useMediaQuery'

SwiperCore.use([Autoplay, Navigation, Pagination])
export default function SecondaryBanner({ backgroundBanners }) {
  const router = useRouter()
  const isMobile = useMediaQuery('(max-width: 767px)')

  return (
    <Swiper
      className="secondary-banner-swiper"
      spaceBetween={50}
      slidesPerView={1}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}
      style={{
        height: 'fit-content',
      }}
    >
      {backgroundBanners
        .sort((a, b) => b?.priority - a?.priority)
        .map((banner) => {
          const imgUrl =
            process.env.BASE_IMAGE_BANNER_URL +
            (isMobile
              ? banner?.translations?.mn_MN?.mobileImageName
              : banner?.translations?.mn_MN?.imageName)

          return (
            <SwiperSlide key={banner?.translations?.mn_MN?.imageName}>
              <img
                onClick={() => {
                  router.push(banner?.translations?.mn_MN?.url || '#')
                }}
                src={imgUrl}
                className="w-100 h-auto cursor-pointer"
              />
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}
