import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import SquareBanner from '../banners/square-banner'
import ViewTitle from 'components/core/components/TextSection'
import Link from 'next/link'

const DiscountByCategory = ({ isMobile, banners }) => {
  // Memoize the rendered banners to avoid unnecessary re-renders
  // const renderedBanners = useMemo(
  //   () =>
  //     banners.map(
  //       (banner, index) =>
  //         index != 2 && (
  //           <SquareBanner key={banner.code} imgPath={banner} banner={banner} />
  //         )
  //     ),
  //   [banners]
  // )
  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })

  const url = banners[2]?.translations?.mn_MN?.url
  const mobileImageName = banners[2]?.translations?.mn_MN?.mobileImageName
  return (
    <div className="d-flex align-items-center flex-column gap-3">
      <ViewTitle
        title={'Онцлох хямдралтай ангилал'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
        link={'/discount'}
      />
      {isMobile ? (
        <div className="d-flex flex-column gap-3">
          <div className="blackfriday-grid-container">
            {banners.map(
              (banner, index) =>
                index != 2 && (
                  <SquareBanner
                    key={banner.code}
                    imgPath={banner}
                    banner={banner}
                  />
                )
            )}
          </div>
          <Link href={url || '#'}>
            <img
              src={encodeImageURI(mobileImageName)}
              alt="Special Offer Banner"
              className="w-100 h-auto banner-img long-banner"
            />
          </Link>
        </div>
      ) : (
        <div className="blackfriday-grid-container">
          {banners.map((banner, index) => (
            <SquareBanner key={banner.code} imgPath={banner} banner={banner} />
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(DiscountByCategory)
