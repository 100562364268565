import React from 'react'
import SquareBanner from './squareBanner'
import TwoByOneBanners from './TwoByOneBanners'

const MobileGallery = ({
  isMobile,
  encodeImageURI,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
}) => {
  return (
    <div className="d-md-none d-flex flex-column gap-2">
      <TwoByOneBanners
        isMobile={isMobile}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
      />
      <div className="d-flex justify-content-between gap-2">
        <SquareBanner
          isSwiper={false}
          isMobile={isMobile}
          banner={banner4}
          encodeImageURI={encodeImageURI}
        />
        <SquareBanner
          isSwiper={false}
          isMobile={isMobile}
          banner={banner5}
          encodeImageURI={encodeImageURI}
        />
      </div>
    </div>
  )
}

export default MobileGallery
