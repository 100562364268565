import Skeleton from 'react-loading-skeleton'

const ProductBoxLoading = ({ type, width = 255 }) => {
  return (
    <div className={`single-product ${type}`}>
      <div className="image">
        <Skeleton height={250} width={width} />
      </div>
      <a href={'#'} className="detail">
        <p className="name">
          <Skeleton height={20} width={`60%`} />
        </p>
        <div className="price-container">
          <ul className="list-unstyled d-flex align-items-center">
            <li className="price">
              <p>
                <Skeleton height={20} width={`20%`} />
              </p>
            </li>
            <li className="sale">
              <p>
                <Skeleton height={20} width={`20%`} />
              </p>
            </li>
          </ul>
        </div>
      </a>
    </div>
  )
}
export default ProductBoxLoading
