import { useRouter } from 'next/router'
import { useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import { useMostmoneyLogin } from 'actions/auth'
import { useAuthState } from 'contexts/auth'
import toast from '@/components/global/toastMessage'
import Loader from '@/components/loading/loader'

const Mostmoney = () => {
  const [mostmoneyLogin, { error: mostmoneyError }] = useMostmoneyLogin()
  const router = useRouter()
  const { custtkn } = router.query
  const mostmoney = Cookies.get('mini_app')
  const { setLogin } = useAuthState()

  const notify = useCallback((type, message) => {
    toast({ type, message })
  }, [])

  useEffect(() => {
    // Cookies.set('mini_app', 'most_money_test')
    if (custtkn) {
      getMostmoneyLogin()
      if (!mostmoney) {
        Cookies.set('mini_app', 'most_money_test')
      }
    }
    // else
    //     Cookies.remove('mini_app')
  }, [custtkn])

  useEffect(() => {
    mostmoneyError && notify('error', 'Нэвтрэхэд алдаа гарлаа')
  }, [mostmoneyError])

  const getMostmoneyLogin = async () => {
    let data = {
      custTkn: custtkn.toString(),
    }
    mostmoneyLogin(data)
      .then(async (res) => {
        setLogin({
          token: res?.data?.token,
          refresh_token: res?.data?.refresh_token,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return <>{/* {mostmoneyLoading && <Loader />} */}</>
}
export default Mostmoney
