import React from 'react'
import { useGetFeaturedCategories } from 'actions/categories'
import ViewTitle from 'components/core/components/TextSection'
import CategorySlider from 'components/core/components/category/CategorySlider'

export default function HiglightCategory({
  title,
  subTitle,
  image = '',
  showArrow,
}) {
  const { data, loading } = useGetFeaturedCategories({
    subUrl: 'shop/taxons-by-feature',
  })

  return (
    <div className="new-product-list">
      <ViewTitle title={title} subTitle={subTitle} />
      <CategorySlider datas={data?.data} image={image} showArrow={showArrow} />
    </div>
  )
}
