import { chunk } from 'lodash'
import React, { useMemo } from 'react'
import CategoryItem from './CategoryItem'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'

SwiperCore.use([Navigation, Pagination, Autoplay])
export default function CategorySlider({ showArrow = true, datas = [] }) {
  const getCatgories = useMemo(() => {
    return chunk(datas, datas.length / 2) || []
  }, [datas])

  return (
    <>
      <div className="container px-4 text-center box-sizing position-relative overflow-hidden">
        <div className="justify-content-center px-lg-3 mx-lg-2 box-sizing">
          <div className="px-0 mx-0 box-sizing position-relative">
            <Swiper
              slidesPerView={2}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              setWrapperSize={false}
              // grabCursor={true}
              pagination={{
                bulletActiveClas: 'p-bullet-active',
                bulletClass: 'p-bullet',
                el: '.p-slider-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: '.p-slider-next-button',
                prevEl: '.p-slider-prev-button',
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
              loop={true}
            >
              <div className="swiper-wrapper px-4 mx-4 box-sizing">
                {(getCatgories[0] || []).map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      <SwiperSlide className="box-sizing" key={index}>
                        <CategoryItem item={item} />
                      </SwiperSlide>
                    </div>
                  )
                })}
              </div>
              {/* <div
              className="swiper-pagination p-slider-pagination"
              style={{ bottom: 0 }}
            /> */}
            </Swiper>
            <Swiper
              slidesPerView={2}
              setWrapperSize={false}
              // grabCursor={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                bulletActiveClas: 'p-bullet-active',
                bulletClass: 'p-bullet',
                el: '.p-slider-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: '.p-slider-next-button',
                prevEl: '.p-slider-prev-button',
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
              loop={true}
            >
              <div className="swiper-wrapper px-4 mx-4 box-sizing">
                {(getCatgories[1] || []).map((item, index) => (
                  <div className="position-relative" key={index}>
                    <SwiperSlide className="box-sizing" key={index}>
                      <CategoryItem item={item} />
                    </SwiperSlide>
                  </div>
                ))}
              </div>
              <div
                className="swiper-pagination p-slider-pagination"
                style={{ bottom: 0 }}
              />
            </Swiper>
          </div>
          {showArrow ? (
            <>
              <div
                className="swiper-button-prev p-slider-prev-button"
                style={{ left: 0 }}
              />
              <div
                className="swiper-button-next p-slider-next-button"
                style={{ right: 0 }}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
