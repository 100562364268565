import { useEffect, useState } from 'react'

const CountDown = ({ targetDate, targetTime, isHome = false }) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [isExpired, setIsExpired] = useState(0)

  useEffect(() => {
    setDate()
    let timer = setInterval(() => {
      setDate()
    }, 1000)
    return () => {
      clearInterval(timer)
      timer = null
    }
  }, [])

  function setDate() {
    const now = new Date().getTime()
    const countDownDate = new Date(targetDate + ' ' + targetTime).getTime()
    const distance = countDownDate - now
    if (distance < 0) {
      clearInterval()
      setIsExpired(true)
    } else {
      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)))
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      )
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000))
      setIsExpired(false)
    }
  }

  function makeDoubleDigits(number) {
    if (number < 10) return '0' + number
    return number
  }

  return (
    <>
      {!isExpired && targetDate && targetTime ? (
        
       <>
        { isHome ? <> {days === 0
          ? +makeDoubleDigits(hours) +
            ':' +
            makeDoubleDigits(minutes) +
            ':' +
            makeDoubleDigits(seconds)
          : days +
            ' өдөр ' +
            makeDoubleDigits(hours) +
            ':' +
            makeDoubleDigits(minutes) +
            ':' +
            makeDoubleDigits(seconds)}{' '}</> : <p className="timer">
        {days === 0
          ? +makeDoubleDigits(hours) +
            ':' +
            makeDoubleDigits(minutes) +
            ':' +
            makeDoubleDigits(seconds)
          : days +
            ' өдөр ' +
            makeDoubleDigits(hours) +
            ':' +
            makeDoubleDigits(minutes) +
            ':' +
            makeDoubleDigits(seconds)}{' '}
      </p> } 
       </>
      ) : null}
    </>
  )
}

export default CountDown
