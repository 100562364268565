import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import Image from 'next/image'
// import { useGetSlides } from "@/actions/banners";
import Link from 'next/link'
import isEmpty from '@/utils/isEmpty'

SwiperCore.use([Navigation, Pagination, Autoplay])

const MainSlide = ({ mainSlides }) => {
  // const { data: allBannersData } = useGetSlides({
  //   subUrl: `shop/banners?enabled=true&taxons.code=home_banner`,
  //   initData: mainSlides,
  // })
  const placeholderImg = '/product-img-placeholder.svg'
  return (
    // Үндсэн слайдz
    <section className="main-slide-container d-flex order-sm-1 order-2 pb-2">
      <Swiper
        className={'main-slide'}
        navigation={true}
        centeredSlides={true}
        pagination={{
          el: '.main-slide-pagination',
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {mainSlides && mainSlides.sort((a, b)=>{return a?.priority-b?.priority}).map((slide, i) => {
            const mainSlides = slide?.translations?.mn_MN?.imageName
            const mobileMainSlide = slide?.translations?.mn_MN?.mobileImageName

            return (
              <SwiperSlide key={`slide_${i}`}>
                <Link
                  href={`${
                    isEmpty(slide?.translations?.mn_MN?.url)
                      ? '/'
                      : slide?.translations?.mn_MN?.url
                  }`}
                >
                  <a>
                    <div className="d-none d-md-block d-lg-block">
                      <Image
                        src={
                          mainSlides
                            ? process.env.BASE_IMAGE_BANNER_URL + mainSlides
                            : placeholderImg
                        }
                        alt={
                          slide?.translations?.mn_MN?.mainText ||
                          'Main Slide Image'
                        }
                        width={2300}
                        height={600}
                        objectFit="cover"
                        loading='lazy'
                        placeholder="blur"
                        blurDataURL={
                          mainSlides
                            ? process.env.BASE_IMAGE_BANNER_URL + mainSlides
                            : placeholderImg
                        }
                        unoptimized={true}
                        decoding='async'
                        quality={80}
                      />
                    </div>
                    <div className="d-block d-md-none d-lg-none">
                      <Image
                        src={
                          mobileMainSlide
                            ? process.env.BASE_IMAGE_BANNER_URL +
                              mobileMainSlide
                            : placeholderImg
                        }
                        alt={
                          slide?.translations?.mn_MN?.secondaryText ||
                          'Main Slide Image'
                        }
                        width={450}
                        height={450}
                        objectFit="cover"
                        loading='lazy'
                        placeholder="blur"
                        blurDataURL={
                          mobileMainSlide
                            ? process.env.BASE_IMAGE_BANNER_URL +
                              mobileMainSlide
                            : placeholderImg
                        }
                        unoptimized={true}
                        decoding='async'
                        quality={85}
                      />
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
            )
          })}
        <div className="swiper-pagination main-slide-pagination" />
      </Swiper>
    </section>
    // Үндсэн слайд
  )
}
export default MainSlide
