import React from 'react'
import { FaPercent } from 'react-icons/fa'

const Discount = ({ disc, isMobile }) => {
  return (
    (disc || disc <= 0) && (
      <div
        className="text-white"
        style={{
          position: 'absolute',
          top: isMobile ? '12px' : '',
          right: isMobile ? '20px' : '',
          left: isMobile ? '' : '25px',
          bottom: isMobile ? '' : '8px',
        }}
      >
        <div style={{ position: 'relative' }}>
          <span
            className="text-white"
            style={{
              fontWeight: 800,
              fontSize: isMobile ? '20px' : '35px',
            }}
          >
            {disc}
          </span>
          <span
            className="absolute"
            style={{
              position: 'absolute',
              display: 'flex',
              fontWeight: 800,
              backgroundColor: 'red',
              height: isMobile ? '15px' : '25px',
              width: isMobile ? '15px' : '25px',
              borderRadius: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: isMobile ? '10px' : '15px',
              top: isMobile ? '-2px' : '0px',
              right: isMobile ? '-10px' : '-18px',
            }}
          >
            {/* <FaPercent /> */}%
          </span>
        </div>
      </div>
    )
  )
}

export default Discount
