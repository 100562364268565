import React, { useCallback, useEffect } from 'react'
import clsx from 'clsx'
import TravelBanners from '../travelBanners'
import useMediaQuery from 'components/hooks/useMediaQuery'
import DiscountByPercentage from './discount-by-percentage'
import DiscountByCategory from './discount-by-category'

const BlackFriday = ({ travelBanners, banners }) => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })
  const groupedBanners = banners?.reduce((acc, banner) => {
    const [, groupKey] = banner?.code.split('-')
    if (!acc[groupKey]) {
      acc[groupKey] = []
    }
    acc[groupKey].push(banner)
    return acc
  }, {})

  const header = groupedBanners?.['header']?.[0]
  const headerImageName = header?.translations?.mn_MN?.imageName
  const headerMobileImageName = header?.translations?.mn_MN?.mobileImageName
  const footer = groupedBanners?.['footer']?.[0]
  const footerImageName = footer?.translations?.mn_MN?.imageName
  const footerMobileImageName = footer?.translations?.mn_MN?.mobileImageName
  return (
    <div className="blackfriday">
      <div className="w-100 h-auto">
        <img
          src={encodeImageURI(
            isMobile ? headerMobileImageName : headerImageName
          )}
          alt="bf-header"
          loading="lazy"
          className="banner-img"
        />
      </div>
      <div
        className={clsx(
          'd-flex container  flex-column  my-3',
          isMobile && 'px-4 gap-3',
          !isMobile && 'px-5 gap-5'
        )}
      >
        <DiscountByPercentage
          isMobile={isMobile}
          banners={groupedBanners?.['1'] || []}
        />
        <DiscountByCategory
          isMobile={isMobile}
          banners={groupedBanners?.['2'] || []}
        />
        <TravelBanners travelBanners={travelBanners} isMobile={isMobile} />
      </div>
      <div className="w-100 h-auto">
        <img
          src={encodeImageURI(
            isMobile ? footerMobileImageName : footerImageName
          )}
          alt="bf-header"
          loading="lazy"
          className="banner-img"
        />
      </div>
    </div>
  )
}

export default BlackFriday
