import Link from 'next/link'
import React, { useCallback } from 'react'

const SquareBanner = ({ imgPath, isMobile, banner }) => {
  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })
  const url = banner?.translations?.mn_MN?.url || '#'
  const imageName = banner?.translations?.mn_MN?.imageName
  const mobileImageName = banner?.translations?.mn_MN?.mobileImageName
  return (
    <Link href={url}>
      <div className="square-banner">
        <img
          src={encodeImageURI(isMobile ? mobileImageName : imageName)}
          alt="image"
          className="banner-img"
          loading="lazy"
        />
      </div>
    </Link>
  )
}

export default SquareBanner
