import React, { useMemo } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import Discount from './discount'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'

SwiperCore.use([Navigation, Pagination, Autoplay])

const SquareBanner = ({ className, banner, isMobile, encodeImageURI }) => {
  const url = banner?.url
  const titleText = banner?.mainText?.split('&')?.[0] || ''
  const disc = banner?.mainText?.split('&')?.[1] || undefined

  const imgPath = banner
    ? encodeImageURI(isMobile ? banner?.mobileImageName : banner?.imageName)
    : ''

  return (
    <Link href={url || '#'}>
      <div
        className={clsx(
          'w-100 position-relative overflow-hidden cursor-pointer',
          className || 'h-50',
          isMobile ? 'rounded-4' : 'rounded-5',
          'bg-image'
        )}
        style={{
          aspectRatio: '1/1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${imgPath})`,
        }}
      >
        {/* dark overlay for the banner start */}
        <div
          className="absolute top-0 left-0 right-0 bottom-0 h-100 w-100"
          style={{
            backgroundColor: 'rgba(0,0,0,0.1)',
          }}
        />
        {/* dark overlay for the banner end */}
        {/* disc start */}
        <Discount disc={disc} isMobile={isMobile} />
        {/* disc end */}
        <span
          className="position-absolute text-white font-weight-bold flex-wrap lh-1"
          style={{
            fontSize: isMobile ? '20px' : '35px',
            top: isMobile ? '15px' : '25px',
            left: isMobile ? '15px' : '25px',
          }}
          dangerouslySetInnerHTML={{ __html: titleText }}
        />
        {/* Бүгдийг харах button */}
        <button
          className="banner-btn position-absolute"
          style={{
            right: isMobile ? '15px' : '20px',
            bottom: isMobile ? '15px' : '20px',
            color: 'black',
          }}
        >
          Бүгдийг харах
        </button>
      </div>
    </Link>
  )
}

export default SquareBanner
