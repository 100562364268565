import React, { useCallback } from 'react'
import clsx from 'clsx'
import MobileGallery from './mobileGallery'
import DesktopGallery from './desktopGallery'
import useMediaQuery from 'components/hooks/useMediaQuery'
import ViewTitle from 'components/core/components/TextSection'

const TravelBanners = ({ travelBanners, isMobile }) => {
  travelBanners = travelBanners?.sort((a, b) => {
    return a?.priority - b?.priority
  })

  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })
  const banner1 = isMobile
    ? travelBanners?.[3]?.translations?.mn_MN
    : travelBanners?.[0]?.translations?.mn_MN
  const banner2 = travelBanners?.[1]?.translations?.mn_MN
  const banner3 = travelBanners?.[2]?.translations?.mn_MN
  const banner4 = isMobile
    ? travelBanners?.[0]?.translations?.mn_MN
    : travelBanners?.[3]?.translations?.mn_MN
  const banner5 = travelBanners?.[4]?.translations?.mn_MN

  return (
    <div className="d-flex flex-column gap-3">
      <ViewTitle
        title={'ОНЦЛОХ ХЯМДРАЛТАЙ БРЭНД'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
        link={'/discount'}
      />
      {/* <h2 className="mx-auto">ОНЦЛОХ ХЯМДРАЛТАЙ БРЭНД</h2> */}
      <MobileGallery
        isMobile={isMobile}
        travelBanners={travelBanners}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
        banner4={banner4}
        banner5={banner5}
      />
      <DesktopGallery
        isMobile={isMobile}
        travelBanners={travelBanners}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
        banner4={banner4}
        banner5={banner5}
      />
    </div>
  )
}

export default TravelBanners
