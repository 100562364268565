import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import SquareBanner from '../banners/square-banner'
import Link from 'next/link'

const DiscountByPercentage = ({ isMobile, banners }) => {
  // Memoize the rendered banners to avoid recalculating on every render
  const renderedBanners = useMemo(() => {
    return banners.map((banner, index) => {
      return (
        index !== 6 && (
          <SquareBanner
            key={banner.code}
            banner={banner}
            isMobile={isMobile}
            imgPath={'/static/images/test/Frame 1171275460.png'}
          />
        )
      )
    })
  }, [])

  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })

  const lastBanner = banners[6]
  const url = lastBanner?.translations?.mn_MN?.url || '#'
  const imageName = lastBanner?.translations?.mn_MN?.imageName
  const mobileImageName = lastBanner?.translations?.mn_MN?.mobileImageName

  return (
    <div className={clsx('d-flex flex-column', isMobile ? 'gap-3' : 'gap-5')}>
      <div className="blackfriday-grid-container">{renderedBanners}</div>
      <Link href={url}>
        <img
          src={encodeImageURI(isMobile ? mobileImageName : imageName)}
          alt="70%-discount"
          className="w-full banner-img long-banner"
        />
      </Link>
    </div>
  )
}

// Export the memoized component to prevent unnecessary re-renders
export default React.memo(DiscountByPercentage)
