import Image from 'next/image'
import Link from 'next/link'
import React, { useMemo } from 'react'
import isEmpty from 'utils/isEmpty'
const placeholderImg = '/product-img-placeholder.svg'
export default function BrandItem({brand}) {

  const defaultValues = useMemo(() => ({
    brand: brand?.translations?.mn_MN
  }) ,[brand])
  return (
    <div className='d-flex position-relative category-slide-image my-4 px-2'>
      <Link href={isEmpty(defaultValues.brand?.url) ? '/' : defaultValues.brand?.url}>
      <img 
      className='img-thumbnail border-0 pt-0 py-0 px-0  shadow-sm'
      src={ defaultValues.brand ? process.env.BASE_IMAGE_BANNER_URL + defaultValues.brand?.imageName
        : placeholderImg}
      />
      </Link>
     </div>

  
    //  <Image 
    // objectFit='contain'
    // src="https://icentomm.sirv.com/Aris%20archilga%20copy/Alienware%20copy.webp"
    // height={264}
    // width={218}
    // layout='responsive'
    // loading='lazy'
    // quality={85}
    // />
  )
}
