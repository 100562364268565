import React, { useEffect, useState } from 'react'
import ViewTitle from 'components/core/components/TextSection'
import BrandSlider from 'components/core/components/brand/BrandSlider'
import ProductBoxLoading from 'components/loading/productBoxLoading'

export default function Brand({
  isColumn = false,
  title,
  subTitle,
  showArrow,
  brands = [],
}) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (brands.length > 0) {
      setLoading(false)
    }
  }, [brands])

  return (
    <div className="new-product-list">
      <ViewTitle title={title} subTitle={subTitle} />
      {!loading && (
        <BrandSlider
          brands={brands}
          isColumn={isColumn}
          showArrow={showArrow}
        />
      )}

      {loading && (
        <>
          <div className="container row mt-4 mx-auto row d-none d-xl-flex">
            {Array.from(Array(5)).map((val, ind) => {
              return (
                <div className="col" key={ind}>
                  <ProductBoxLoading width={'100%'} />
                </div>
              )
            })}
          </div>
          <div className="container row mt-4 mx-auto row d-none d-md-flex d-xl-none">
            {Array.from(Array(4)).map((val, ind) => {
              return (
                <div className="col" key={ind}>
                  <ProductBoxLoading width={'100%'} />
                </div>
              )
            })}
          </div>
          <div className="container row mt-4 mx-auto row d-md-none">
            {Array.from(Array(2)).map((val, ind) => {
              return (
                <div className="col" key={ind}>
                  <ProductBoxLoading width={'100%'} />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
