import { toast } from 'react-toastify'
import {
  FaInfo,
  FaCheck,
  FaExclamationTriangle,
  FaBug,
  FaExclamationCircle,
} from 'react-icons/fa'

export const displayIcon = (type) => {
  switch (type) {
    case 'success':
      return <FaCheck />
    case 'info':
      return <FaInfo />
    case 'error':
      return <FaExclamationCircle />
    case 'warning':
      return <FaExclamationTriangle />
    default:
      return <FaBug />
  }
}

const ToastMessage = ({ type, message, time }) =>
  toast[type](
    <div style={{ display: 'flex', color: 'white' }}>
      <div
        style={{
          fontSize: 15,
          paddingTop: 8,
          flexShrink: 0,
          textAlign: 'center',
          width: '30px',
        }}
      >
        {displayIcon(type)}
      </div>
      <div style={{ flexGrow: 1, fontSize: 15, padding: '8px 12px' }}>
        {message}
      </div>
    </div>,
    { autoClose: time ? time : 3000 }
  )

export default ToastMessage
