import moment from 'moment'
import Link from 'next/link'
import Numeral from 'numeral'
import CountDown from 'components/global/countDown'
import React, { useCallback, useMemo, useState } from 'react'

const placeholderImg = '/product-img-placeholder.svg'

export default function ProductItem({ product }) {
  const [isHovering, setIsHovered] = useState(false)
  const [isAdult] = useState(product?.isAdult || false)
  const [isGift] = useState(product?.isGift || false)
  const productImage = product?.images[0]?.productThumbnail
  const productImagePath = product.images ? product?.images[0]?.path : ''
  const [nowDate] = useState(moment().format('YYYY-MM-DD'))

  const price = parseInt(
    product?.variants[0]?.channelPricings?.FASHION_WEB?.originalPrice
  )
  const salePrice = parseInt(
    product?.variants[0]?.channelPricings?.FASHION_WEB?.price || 0
  )

  const defaultValues = useMemo(
    () => ({
      productImage: product.images ? product?.images[0]?.productThumbnail : '',
      productImage2: isAdult
        ? productImage
        : product?.images[1]?.productThumbnail,
      productImagePath: product.images ? product?.images[0]?.path : '',
      productImagePath2: isAdult ? productImagePath : product?.images[1]?.path,
      brandImageLogo:
        product?.brand && product?.brand?.images[0]?.logoThumbnail,
      endTime: product?.variants
        ? product?.variants[0]?.channelPricings?.FASHION_WEB?.promotionEndsAt
        : '',
      productDate: moment(product?.createdAt)
        .add(10, 'days')
        .format('YYYY-MM-DD'),
      // endTime: "2023-10-15T23:59:00+08:00"
    }),
    [product, isAdult]
  )

  const [date] = useState(moment(defaultValues.endTime).format('ll'))
  const [time] = useState(moment(defaultValues.endTime).format('HH:mm:ss'))

  const now = new Date().getTime()
  const countDownDate = new Date(date + ' ' + time).getTime()
  const distance = countDownDate - now

  const percentage = Math.round(
    (100 * ((price || 0) - (salePrice || 0))) / price || 0
  )

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const getVarianStock = useMemo(() => {
    const inStock =
      product &&
      (product?.variants || []).find((variant) => variant.inStock === true) !==
        undefined
        ? true
        : false
    return inStock
  }, [product])

  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_URL + encodeURIComponent(image)
  })

  return (
    <div
      className="product-ui"
      style={{ position: 'relative' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link href={`/product/${product?.slug}`}>
        {isHovering && defaultValues.productImage2 ? (
          <img
            src={
              defaultValues.productImagePath2
                ? encodeImageURI(defaultValues.productImagePath2)
                : placeholderImg
            }
            className={`img-product-ui img-thumbnail ${isAdult ? 'blur' : ''}`}
          />
        ) : (
          <img
            src={
              defaultValues.productImagePath
                ? encodeImageURI(defaultValues.productImagePath)
                : placeholderImg
            }
            className={`img-product-ui img-thumbnail border-0 ${
              isAdult ? 'blur' : ''
            }`}
          />
        )}
      </Link>
      {!!defaultValues.brandImageLogo && (
        <div style={{ position: 'absolute' }}>
          <Link href={`/brands/${product?.brand?.id}`}>
            <img className="p-brand-img" src={defaultValues.brandImageLogo.replace("http://", "https://")} />
          </Link>
        </div>
      )}
      <div className="flex-column p-content">
        <p className="p-text">
          {product?.name ? product?.name : product?.translations?.mn_MN?.name}
        </p>
        <div className="d-flex flex-row">
          {salePrice < price ? (
            <p className="p-sub-text-root p-sub-text-sale text-decoration-line-through">
              {Numeral(price).format('0,0')} ₮
            </p>
          ) : null}
          <p className="p-sub-text-root p-sub-text">
            {Numeral(salePrice < price ? salePrice : price).format('0,0')} ₮
          </p>
        </div>

        {defaultValues.endTime && getVarianStock ? (
         distance > 0 && <div className="p-counter">
            <div className="p-counter-text">
             <CountDown targetDate={date} targetTime={time} isHome={true} />
            </div>
          </div>
        ) : null}
        {!getVarianStock ? (
          <div className="p-outofstock">
            <span>Дууссан</span>
          </div>
        ) : null}
      </div>
      {/* <div className="position-absolute new-ribbon">

      </div> */}
      {salePrice < price
        ? percentage !== 0 && (
            <div className="position-absolute p-sale-badget">
              <p className="p-sale-badget-text">{percentage}%</p>
            </div>
          )
        : null}

      {nowDate <= defaultValues.productDate ? (
        <div className="p-new-badge">
          <span>New</span>
        </div>
      ) : null}
    </div>
  )
}
